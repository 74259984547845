import React from 'react'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import Layout from '../components/layout'
import Header from '../components/Header'
import BreadCrumb from '../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Datenschutz',
  },
]

const DataPrivacy = () => {
  return (
    <Layout>
      <Menu />
      <Header page="data-privacy" />
      <div className="big-background-image pb-8  bg-lendis-black bg-no-repeat bg-top lg:pb-40 pt-40 px-3">
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Datenschutz</h1>
        </div>
        <BreadCrumb breadCrumbItems={breadcrumb} textPage />
        <div className="bg-white mx-auto container">
          <div className="py-12">
            <div className="mx-8 mt-8 lg:ml-16 lg:mr-16">
              <h2 className="text-2xl uppercase">Datenschutzerklärung</h2>
              <p>
                Als Betreiberin dieser Seite nimmt die Lendis GmbH („Lendis“ oder „wir“) den Schutz
                Ihrer personenbezogenen Daten sehr ernst. Der Schutz Ihrer Privatsphäre bei der
                Verarbeitung personenbezogener Daten ist für uns ein wichtiges Anliegen. Im
                Folgenden möchten wir Sie darüber informieren, welche Daten wir erheben und
                verarbeiten, wenn Sie bspw. unseren Internet-Auftritt (nachfolgend auch “Webseite“)
                besuchen oder die von uns anderweitig angebotenen Services und Dienstleistungen
                (nachfolgend „Services“) nutzen.
              </p>
              <h4 className="uppercase font-semibold mt-4">Verantwortlicher</h4>
              Verantwortlicher im Sinne der EU-Datenschutzgrundverordnung (DSGVO) ist die heyData UG
              (haftungsbeschränkt)
              <br />
              Landsberger Straße 155, 80687 München
              <br />
              <a className="text-lendis-main" href="mailto:datenschutz@heydata.de">
                datenschutz@heydata.de
              </a>
              <br />
              <a
                className="text-lendis-main"
                href="http://www.heydata.de"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.heydata.de
              </a>
              <h4 className="uppercase font-semibold mt-4">Inhalt</h4>
              Die nachfolgende Datenschutzerklärung enthält folgende Abschnitte:
              <ul className="list-disc list-inside">
                <li>
                  Protokollierung von nicht-personenbezogenen Daten bei der Nutzung unseres
                  Internet-Auftritts
                </li>
                <li>E-Mail-Werbung ohne Anmeldung zum Newsletter und Ihr Widerspruchsrecht</li>
                <li>Erhebung und Verwendung von personenbezogenen Daten</li>
                <li>Aus anderen Quellen erhobene personenbezogene Daten</li>
                <li>Rechtsgrundlage für die Verarbeitung</li>
                <li>Datenübermittlung an Dritte</li>
                <li>Datenübermittlung in Drittländer</li>
                <li>Speicherdauer, Löschung von personenbezogenen Daten</li>
                <li>Ihre Rechte</li>
                <li>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</li>
                <li>Datensicherheit</li>
                <li>Kontaktformular</li>
                <li>Cookies</li>
                <li>Google Analaytics</li>
                <li>Browser Plugin</li>
                <li>Google AdWords und Google Conversion-Tracking</li>
                <li>Bonitätsprüfung & Credit Scoring</li>
                <li>Social Media</li>
                <li>Facebook (Plug Ins, Remarketing/Custom Audiences)</li>
                <li>Segment</li>
                <li>Intercom</li>
                <li>Mailchimp</li>
                <li>Hotjar</li>
                <li>ELDAR</li>
                <li>Profiling</li>
                <li>Änderungen dieser Datenschutzerklärung</li>
                <li>Kontakt</li>
              </ul>
              <h4 className="uppercase font-semibold mt-4">Grundsätzlich gilt:</h4>
              <p>
                Sie können unsere Services in Anspruch nehmen und unsere Webseite besuchen, ohne
                Angaben zu Ihrer Person zu machen. Personenbezogene Daten werden nur erhoben, wenn
                Sie uns diese im Rahmen Ihres Besuchs auf unserem Internet-Auftritt freiwillig
                mitteilen. Daten sind personenbezogen, wenn sie einer bestimmten oder bestimmbaren
                Person zugeordnet werden können. Hierunter fallen z. B. Angaben wie Name, Post- und
                E-Mail-Adresse oder Telefonnummer, ggf. aber auch Nutzungsdaten wie Ihre IP-Adresse.
              </p>
              <h4 className="uppercase font-semibold mt-4">
                Protokollierung von nicht-personenbezogenen Daten bei der Nutzung unseres
                Internet-Auftritts
              </h4>
              <p>
                Wenn Sie unseren Internet-Auftritt aufrufen oder Daten von der Webseite oder einer
                ihrer Unterseiten herunterladen, werden hierüber Informationen in einer
                Protokolldatei gespeichert und verarbeitet. Dieser Vorgang erfolgt anonymisiert.
                Rückschlüsse auf Ihre Person sind nicht möglich.
              </p>
              <p>
                Je nach verwendetem Zugriffsprotokoll beinhaltet der Protokolldatensatz Angaben mit
                folgenden Inhalten:
              </p>
              <ul className="list-disc list-inside">
                <li> Name der angeforderten Datei, das Datum und die Uhrzeit des Seitenaufrufs</li>
                <li> IP-Adresse des anfordernden Rechners</li>
                <li> vom anfordernden Rechner gewünschte Zugriffsmethoden/Funktionen</li>
                <li> die aufgerufene Webseite bzw. der Name der abgerufenen Datei</li>
                <li> Betriebssystem und Browsertyp bzw. Browsereinstellungen</li>
                <li>
                  die übertragene Datenmenge und die Meldung, ob der Zugriff/Abruf erfolgreich war
                </li>
              </ul>
              <p>
                Es gibt für uns keine Möglichkeit, IP-Adresse und eventuell vorhandene
                personenbezogene Daten zu verknüpfen. Die gespeicherten Daten werden ausschließlich
                zu Zwecken der Identifikation und Nachverfolgung unzulässiger
                Zugriffsversuche/Zugriffe auf den Web-Server sowie für statistische Auswertungen wie
                Besucherzahlen und Seitenpopularität und zur Verbesserung unseres Online-Angebotes
                verwendet. Diese Daten werden ausschließlich von uns verwendet. Eine Weitergabe an
                Dritte erfolgt nicht.
              </p>
              <h4 className="uppercase font-semibold mt-4">
                E-Mail-Werbung ohne Anmeldung zum Newsletter und Ihr Widerspruchsrecht
              </h4>
              <p>
                Wenn wir Ihre E-Mail-Adresse im Zusammenhang mit dem Verkauf einer Ware oder
                Dienstleistung erhalten und Sie dem nicht widersprochen haben, behalten wir uns vor,
                Ihnen auf Grundlage von § 7 Abs. 3 UWG regelmäßig Angebote zu ähnlichen Produkten,
                wie den bereits gekauften, aus unserem Sortiment per E-Mail zuzusenden. Dies dient
                der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten
                Interessen an einer werblichen Ansprache unserer Kunden.
              </p>
              <p>
                Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch eine Nachricht an
                die unten beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link in
                der Werbemail widersprechen, ohne dass hierfür andere als die Übermittlungskosten
                nach den Basistarifen entstehen.
              </p>
              <h4 className="uppercase font-semibold mt-4">
                Erhebung und Verwendung von personenbezogenen Daten
              </h4>
              <p>
                Damit wir bestimmte Dienstleistungen sowie die Vertragsabwicklung ermöglichen
                können, ist die Erhebung personenbezogener Daten unvermeidlich. Wir erfragen und
                speichern dann Ihre Bestandsdaten (Name, Adresse und Unternehmensname) und Ihre
                Kontaktdaten (z.B. E-Mail, Telefonnummern). Zusätzlich verarbeiten wir Vertragsdaten
                (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie) sowie Zahlungsdaten (z.B.
                Bankverbindung, Zahlungshistorie).
              </p>
              <p>
                Soweit Sie uns personenbezogene Daten zum Zwecke der Kontaktaufnahme mitteilen,
                bleiben diese Daten bei uns lediglich solange gespeichert, wie dies zum Zwecke der
                jeweiligen Kommunikation und des Kontakts erforderlich ist. Sobald die
                personenbezogenen Daten von uns zu diesen Zwecken nicht mehr benötigt werden und
                keine längeren Aufbewahrungspflichten bestehen, werden sie umgehend gelöscht.
              </p>
              <p>
                Die erhobenen Daten verwenden wir, um Ihre Anfragen zu beantworten, um Verträge zu
                erfüllen, für Service und Kundenpflege, Marketing und Marktforschung, zur Einhaltung
                gesetzlicher Vorgaben und, sofern Sie ihn bestellt haben, zur Übersendung unseres
                Newsletters. Um unsere Leistungen Ihnen gegenüber zu erbringen, kann es erforderlich
                sein, Ihre personenbezogenen Daten an Unternehmen weiterzugeben, die wir zur
                Erbringung der Dienstleistung oder zur Vertragsabwicklung einsetzen. Dies sind z. B.
                Transportunternehmen, Techniker, IT- und Hosting-Dienstleister,
                Zahlungsdienstleister, Betreiber von Warenwirtschafts-Systemen oder
                Marketing-Newsletter-Dienstleister.
              </p>
              <p>
                Nutzerdaten, die im Zusammenhang mit Bestellvorgängen und Vertragsabschlüssen
                erfasst werden, werden für den Zweck der Zahlungsabwicklung und Zustellung
                elektronisch gespeichert und verarbeitet.
              </p>
              <p>
                Sie können Ihre uns gegenüber erteilte Einwilligungserklärung jederzeit widerrufen
                sowie dem Anlegen von Nutzungsprofilen jederzeit mit Wirkung für die Zukunft
                widersprechen. Daneben können Sie – soweit wir Ihre personenbezogenen Daten im
                gesetzlich zulässigen Rahmen für bspw. postalische Marketingmaßnahmen nutzen –
                dieser Nutzung ebenfalls widersprechen. Es genügt in beiden Fällen eine E-Mail an
                folgende Adresse: info@lendis.io
              </p>
              <h4 className="uppercase font-semibold mt-4">
                Aus anderen Quellen erhobene personenbezogene Daten
              </h4>
              <p>
                Im Rahmen unserer Zusammenarbeit mit Drittdienstleistern erhalten wir möglichweise
                personenbezogene Daten über Sie von diesen Drittdienstleistern. Hierzu zählen z.B.
                Kreditauskunfteien, Service-Dienstleister, Handwerker oder Geschäftspartner.
              </p>
              <h4 className="uppercase font-semibold mt-4">Rechtsgrundlage für die Verarbeitung</h4>
              <p>
                Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer
                Datenverarbeitungen mit. Die Rechtsgrundlage für die Verarbeitung hängt jeweils
                davon ab, für welchen Zweck die Daten verarbeitet werden.
              </p>
              <p>
                Wenn Sie uns Ihre Einwilligung für die Verarbeitung Ihrer personenbezogenen Daten
                gegeben haben, stützen wir die Datenverarbeitung auf die von Ihnen erteilte
                Einwilligung gemäß Art. 6 Abs. 1 lit. a) DSGVO.
              </p>
              <p>
                Die Verarbeitung Ihrer personenbezogenen Daten kann jedoch auch aus den folgenden
                Gründen erforderlich sein, etwa zur Erfüllung eines mit Ihnen geschlossenen Vertrags
                oder zur Durchführung von Ihnen angeforderter vorvertraglicher Maßnahmen und um
                Ihnen unsere Services zur Verfügung zu stellen (z.B. Abwicklung von Bestellvorgängen
                und Mietvertragsabschlüssen, Feedbackanfragen, Online-Umfragen zu unserer
                Geschäftsbeziehung, Angebote, Rechnungen, Zahlungserinnerungen) sowie um unsere
                gesetzlichen Verpflichtungen zu erfüllen (Art. 6 Abs. 1 S. 1 lit. a bis c DSGVO).
                Wir verwenden Ihre personenbezogenen Daten außerdem gem. Art. 6 Abs. 1 S. 1 lit. f
                DSGVO, um unsere berechtigten Interessen zu verfolgen, sofern Ihre Rechte und
                Freiheiten nicht überwiegen.
              </p>
              <h4 className="uppercase font-semibold mt-4">Datenübermittlung an Dritte </h4>
              <p>
                Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden
                aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an
                Dritte weiter, wenn und soweit:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  Sie dazu Ihre ausdrückliche Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
                  erteilt haben,
                </li>
                <li>
                  die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung
                  oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme
                  besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der
                  Nichtweitergabe Ihrer Daten haben,
                </li>
                <li>
                  für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche
                  Verpflichtung besteht, sowie
                </li>
                <li>
                  dies nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von
                  Vertragsverhältnissen mit Ihnen erforderlich ist, wie etwa im Falle der Weitergabe
                  an externe Serviceanbieter, die uns in unserem Geschäftsbetrieb unterstützen.
                </li>
              </ul>
              <p>
                Unsere Mitarbeiter und Partner sind von uns zur Verschwiegenheit und zur Einhaltung
                der datenschutzrechtlichen Vorschriften verpflichtet.
              </p>
              <h4 className="uppercase font-semibold mt-4">Datenübermittlungen in Drittländer</h4>
              <p>
                Wenn wir Ihre personenbezogenen Daten gemäß dieser Datenschutzerklärung teilen, kann
                dies die Übermittlung Ihrer personenbezogenen Daten in Länder außerhalb des
                Europäischen Wirtschaftsraums (EWR) einschließen. Wenn wir Ihre personenbezogenen
                Daten in Länder außerhalb des Europäischen Wirtschaftsraums übermitteln, stellen wir
                stets sicher, dass dort ein adäquates Schutzniveau gewährleistet ist, indem wir –
                sofern dies gesetzlich vorgeschrieben ist – dafür sorgen, dass zumindest eine der
                folgenden geeigneten Garantien besteht:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  Übermittlung von personenbezogener Daten in Länder, die nach Ansicht der
                  Europäischer Kommission ein angemessenes Schutzniveau für personenbezogene Daten
                  bieten (sog. „Angemessenheitsbeschluss“);
                </li>
                <li>
                  Verwendung spezieller, von der Europäischen Kommission genehmigter Verträge,
                  welche personenbezogenen Daten denselben Schutz sichern, den sie in dem EWR
                  genießen;
                </li>
                <li>
                  Übermittlung personenbezogener Daten an eine Einrichtung, die eine gültige
                  „Privacy Shield“-Zertifizierung besitzt, und dadurch ein dem EU Standard ähnliches
                  Schutzniveau für personenbezogene Daten bietet; oder
                </li>
                <li>
                  Übermittlung personenbezogener Daten an eine Einrichtung, die verbindliche,
                  interne Datenschutzvorschriften eingeführt hat, die dem EU Schutzniveau für
                  personenbezogene Daten entsprechen.
                </li>
              </ul>
              <h4 className="uppercase font-semibold mt-4">
                Speicherdauer, Löschung von personenbezogenen Daten
              </h4>
              <p>
                Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO
                gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser
                Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten
                gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
                Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
                nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
                erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden
                gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
                handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
              </p>
              <h4 className="uppercase font-semibold mt-4">Ihre Rechte</h4>
              <p>
                Ihnen stehen verschiedene Rechte in Bezug auf Daten zu, die Ihre Person betreffen.
                Sie haben ein Recht, auf Anfrage unentgeltlich Auskunft über die bei uns zu Ihrer
                Person gespeicherten personenbezogenen Daten zu erhalten, ein Recht auf
                Berichtigung, Löschung oder Einschränkung der Verarbeitung dieser Daten und ein
                Recht auf Widerspruch gegen die Verarbeitung. Ob und inwieweit diese Rechte im
                Einzelfall vorliegen und welche Bedingungen eingreifen, ergibt sich aus der DSGVO
                und dem Bundesdatenschutzgesetz. Nach der DSGVO steht Ihnen zudem grundsätzlich ein
                Recht auf Datenübertragbarkeit zu. Im Übrigen gilt, dass im Fall der Erteilung Ihrer
                Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten diese Einwilligung
                jederzeit mit Wirkung für die Zukunft widerrufen werden kann.
              </p>
              <p>
                Sollten Sie Fragen, Kommentare oder Anfragen bezüglich der Erhebung, Verarbeitung,
                Nutzung oder Löschung Ihrer personenbezogenen Daten durch uns haben, wenden Sie sich
                bitte an{' '}
                <a className="text-lendis-main" href="mailto:info@lendis.io">
                  {' '}
                  info@lendis.io
                </a>
                .
              </p>
              <h4 className="uppercase font-semibold mt-4">
                Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
              </h4>
              <p>
                Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein
                Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde
                bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des
                Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link
                stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit{' '}
                <a
                  className="text-lendis-main"
                  href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                >
                  https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                </a>
              </p>
              <h4 className="uppercase font-semibold mt-4">Datensicherheit</h4>
              <p>
                Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie
                an uns als Seitenbetreiber senden, nutzt unsere Webseite eine SSL-bzw.
                TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Webseite übermitteln, für
                Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der
                „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
              </p>
              <p>
                Im Rahmen des Hostings werden sämtliche im Zusammenhang mit dem Betrieb dieser
                Webseite zu verarbeitenden Daten gespeichert. Dies ist notwendig, um den Betrieb der
                Webseite zu ermöglichen. Die Daten verarbeiten wir entsprechend auf der Grundlage
                unserer berechtigten Interessen gemäß Art. 6 Abs. 1 f) DSGVO. Zur Bereitstellung
                unseres Onlineauftritts nutzen wir Dienste von Webhosting-Anbietern, denen wir die
                oben genannten Daten übermitteln.
              </p>
              <h4 className="uppercase font-semibold mt-4">Kontaktformular</h4>
              <p>
                Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten
                gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen
                bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht
                statt.
              </p>
              <p>
                Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
                ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein
                Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den
                Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
                Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
              </p>
              <p>
                Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur
                Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine
                Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen
                - insbesondere Aufbewahrungsfristen - bleiben unberührt.
              </p>
              <h4 className="uppercase font-semibold mt-4">Cookies</h4>
              <p>
                Unsere Webseite verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser
                auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot
                nutzerfreundlicher, effektiver und sicherer zu machen.
              </p>
              <p>
                Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer
                Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem
                Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei
                Rückkehr auf unserer Webseite wiederzuerkennen.
              </p>
              <p>
                Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen,
                einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass
                Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die
                Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Webseite
                zur Folge haben.
              </p>
              <p>
                Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder
                der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb)
                notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber
                dieser Webseite haben wir ein berechtigtes Interesse an der Speicherung von Cookies
                zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern
                die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in
                dieser Datenschutzerklärung separat behandelt.
              </p>
              <h4 className="uppercase font-semibold mt-4">Google Analytics</h4>
              <p>
                Unsere Webseite verwendet Funktionen des Webanalysedienstes Google Analytics.
                Anbieter des Webanalysedienstes ist die Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA.
              </p>
              <p>
                Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die Ihr
                Webbrowser auf Ihrem Endgerät speichert und eine Analyse der Webseite-Benutzung
                ermöglichen. Mittels Cookie erzeugte Informationen über Ihre Benutzung unserer
                Webseite werden an einen Server von Google übermittelt und dort gespeichert.
                Server-Standort ist im Regelfall die USA.
              </p>
              <p>
                Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
                f DSGVO. Als Betreiber dieser Webseite haben wir ein berechtigtes Interesse an der
                Analyse des Nutzerverhaltens, um unser Webangebot und ggf. auch Werbung zu
                optimieren.
              </p>
              <p>IP-Anonymisierung</p>
              <p>
                Wir setzen Google Analytics in Verbindung mit der Funktion IP-Anonymisierung ein.
                Sie gewährleistet, dass Google Ihre IP-Adresse innerhalb von Mitgliedstaaten der
                Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                Europäischen Wirtschaftsraum vor der Übermittlung in die USA kürzt. Es kann
                Ausnahmefälle geben, in denen Google die volle IP-Adresse an einen Server in den USA
                überträgt und dort kürzt. In unserem Auftrag wird Google diese Informationen
                benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über
                Webseiteaktivitäten zu erstellen und um weitere mit der Webseitenutzung und der
                Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Es findet
                keine Zusammenführung der von Google Analytics übermittelten IP-Adresse mit anderen
                Daten von Google statt.
              </p>
              <p>
                Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie
                das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren:{' '}
                <a
                  href="http://tools.google.com/dlpage/gaoptout?hl=de"
                  className="text-lendis-main"
                >
                  http://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                . Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in
                der&nbsp;
                <a
                  className="text-lendis-main"
                  href="https://support.google.com/analytics/answer/6004245?hl=de"
                >
                  Datenschutzerklärung von Google
                </a>
                .
              </p>
              <h4 className="uppercase font-semibold mt-4">Bonitätsprüfung & Credit Scoring</h4>
              <p>
                Die Lendis GmbH räumt ihren Kunden grundsätzlich die Möglichkeit ein, Waren unter
                Inanspruchnahme unsicherer Zahlungsarten (z.B. Rechnung, Finanzierung) zu erwerben.
                Unternehmen, die ihren Kunden grundsätzlich unsichere Zahlungsarten einräumen, haben
                ein berechtigtes Interesse daran, sich so gut wie möglich vor dem Entstehen von
                Zahlungsausfällen zu schützen. Im Verlaufe von Angebotsprozessen überprüfen wir
                daher ggf. die Bonität Ihres Unternehmens und/oder von Ihnen als Privatperson. Zu
                diesem Zweck übermitteln wir an mit uns kooperierende sogenannte Auskunfteien
                folgende Daten: Gesellschaftsnamen, Namen, Anschrift, ggf. Geburtsdatum. Wir
                übermitteln hierzu Ihre personenbezogenen Daten zur Bonitätsprüfung an folgende
                Unternehmen:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  CRIF Bürgel GmbH, Leopoldstr. 244, 80807 München (mehr Informationen unter{' '}
                  <a
                    className="text-lendis-main"
                    href="https://www.crifbuergel.de/de/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.crifbuergel.de/de/datenschutz
                  </a>
                  )
                </li>
                <li>
                  KSV1870 Holding AG, Wagenseilgasse 7, 1120 Wien, Österreich (mehr Informationen
                  unter{' '}
                  <a
                    className="text-lendis-main"
                    href="https://www.ksv.at/datenschutzerklaerung"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ksv.at/datenschutzerklaerung
                  </a>
                  )
                </li>
                <li>
                  Schufa Holding AG, Kormoranweg 5, 65201 Wiesbaden (mehr Informationen unter{' '}
                  <a
                    className="text-lendis-main"
                    href="https://www.schufa.de/de/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.schufa.de/de/datenschutz/
                  </a>
                  )
                </li>
                <li>
                  Verband der Vereine Creditreform e.V., Hellersbergstraße 12, 41460 Neuss (mehr
                  Informationen unter{' '}
                  <a
                    className="text-lendis-main"
                    href="https://www.creditreform.de/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.creditreform.de/datenschutz
                  </a>
                  )
                </li>
              </ul>
              <p>
                Für die Entscheidung über die Begründung, Durchführung oder Beendigung eines
                Vertragsverhältnisses verwenden wir neben einer Adressprüfung Informationen zu Ihrem
                bisherigen Zahlverhalten sowie Wahrscheinlichkeitswerte zu Ihrem künftigen
                Verhalten, in deren Berechnung unter anderem Anschriftendaten einfließen. Diese
                Informationen beziehen wir von folgenden Anbietern:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  CRIF Bürgel GmbH, Leopoldstr. 244, 80807 München (mehr Informationen unter{' '}
                  <a
                    className="text-lendis-main"
                    href="https://www.crifbuergel.de/de/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.crifbuergel.de/de/datenschutz
                  </a>
                  )
                </li>
                <li>
                  KSV1870 Holding AG, Wagenseilgasse 7, 1120 Wien, Österreich (mehr Informationen
                  unter{' '}
                  <a
                    className="text-lendis-main"
                    href="https://www.ksv.at/datenschutzerklaerung"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ksv.at/datenschutzerklaerung
                  </a>
                  )
                </li>
                <li>
                  Schufa Holding AG, Kormoranweg 5, 65201 Wiesbaden (mehr Informationen unter{' '}
                  <a
                    className="text-lendis-main"
                    href="https://www.schufa.de/de/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.schufa.de/de/datenschutz/
                  </a>
                  )
                </li>
                <li>
                  Verband der Vereine Creditreform e.V., Hellersbergstraße 12, 41460 Neuss (mehr
                  Informationen unter{' '}
                  <a
                    className="text-lendis-main"
                    href="https://www.creditreform.de/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.creditreform.de/datenschutz
                  </a>
                  )
                </li>
              </ul>
              <p>
                Die Bonitätsauskunft und die eigenen Analysen können Wahrscheinlichkeitswerte
                (Score-Werte) beinhalten, die auf Basis wissenschaftlich anerkannter
                mathematisch-statistischer Verfahren berechnet werden und in deren Berechnung unter
                anderem Anschriftendaten einfließen. Rechtsgrundlage hierfür ist Artikel 6 Absatz 1
                Buchstabe f) DSGVO. Das berechtigte Interesse ergibt sich aus unserem Interesse an
                einer Reduzierung des Vertragsrisikos, an einem Schutz vor Forderungsausfällen und
                vor Gefahren der missbräuchlichen Inanspruchnahme unserer Leistungen durch Dritte.
                Ihre schutzwürdigen Belange werden gemäß den gesetzlichen Bestimmungen
                berücksichtigt.
                <br />
                Informationen über die Zahlungsverzögerung oder einen etwaigen Forderungsausfall
                übermitteln wir bei Vorliegen der sonstigen gesetzlichen Voraussetzungen zudem an
                mit uns kooperierende Auskunfteien, wie die Schufa Holding AG, Wiesbaden oder die
                Creditreform Boniversum GmbH. Rechtsgrundlage hierfür ist Artikel 6 Absatz 1
                Buchstabe f) DSGVO. Unser berechtigtes Interesse ergibt sich aus unserem sowie dem
                Interesse Dritter an einer Reduzierung von Vertragsrisiken für zukünftige Verträge.
              </p>
              <h4 className="uppercase font-semibold mt-4">Leadfeeder</h4>
              <p>
                Gleichzeitig mit der Nutzung von Google Analytics verwendet diese Website den Dienst
                Leadfeeder, die von Liidio Oy, Mikonkatu 17, 0100 Helsinki, Finnland, betrieben
                wird. Leadfeeder greift auf die Liste der von Google Analytics in der Auswertung
                bereitgestellten IP-Adressen der Websitebesucher zu und verlinkt die Liste der
                IP-Adressen mit Informationen über die Firmen, die im Internet unter diesen
                IP-Adressen zu finden sind. Aufgrund der schon bei der Nutzung von Google Analytics
                durchgeführten Kürzung der IP-Adressen der Websitebesucher wird ein direkter
                Personenbezug nicht hergestellt. Ein Personenbezug kann sich bei der Durchsicht der
                verlinkten Firmeninformationen vermutungshalber ergeben.
              </p>
              <h4 className="uppercase font-semibold mt-4">Browser Plugin</h4>
              <p>
                Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen
                unserer Webseite könnten dadurch jedoch eingeschränkt werden. Ebenso können Sie die
                Erfassung von Daten bezüglich Ihrer Webseite-Nutzung einschließlich Ihrer IP-Adresse
                mitsamt anschließender Verarbeitung durch Google unterbinden. Dies ist möglich,
                indem Sie das über folgenden Link erreichbare Browser-Plugin herunterladen und
                installieren:{' '}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                  className="text-lendis-main"
                >
                  {' '}
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                .
              </p>
              <p>Widerspruch gegen die Datenerfassung</p>
              <p>
                Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie
                auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung
                Ihrer Daten bei zukünftigen Besuchen unserer Webseite verhindert: Google Analytics
                deaktivieren.
              </p>
              <p>
                Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
                &nbsp;
                <a
                  className="text-lendis-main"
                  href="https://support.google.com/analytics/answer/6004245?hl=de"
                >
                  Datenschutzerklärung von Google
                </a>
              </p>
              <p>
                Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben wir mit
                Google einen Vertrag über die Auftragsverarbeitung abgeschlossen.
              </p>
              <p>Demografische Merkmale bei Google Analytics:</p>
              <p>
                Unsere Webseite verwendet die Funktion “demografische Merkmale” von Google
                Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen zu Alter, Geschlecht
                und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus
                interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern.
                Eine Zuordnung der Daten zu einer bestimmten Person ist nicht möglich. Sie können
                diese Funktion jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in
                Ihrem Google-Konto möglich oder indem Sie die Erfassung Ihrer Daten durch Google
                Analytics, wie im Punkt “Widerspruch gegen die Datenerfassung” erläutert, generell
                untersagen.
              </p>
              <h4 className="uppercase font-semibold mt-4">
                Google AdWords und Google Conversion-Tracking
              </h4>
              <p>
                Unsere Webseite verwendet Google AdWords. Anbieter ist die Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043, United States.
              </p>
              <p>
                AdWords ist ein Online-Werbeprogramm. Im Rahmen des Online-Werbeprogramms arbeiten
                wir mit Conversion-Tracking. Nach einem Klick auf eine von Google geschaltete
                Anzeige wird ein Cookie für das Conversion-Tracking gesetzt. Cookies sind kleine
                Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Google AdWords Cookies
                verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen
                Identifizierung der Nutzer. Am Cookie können Google und wir erkennen, dass Sie auf
                eine Anzeige geklickt haben und zu unserer Webseite weitergeleitet wurden.
              </p>
              <p>
                Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies sind nicht über
                Webseites von AdWords-Kunden nachverfolgbar. Mit Conversion-Cookies werden
                Conversion-Statistiken für AdWords-Kunden, die Conversion-Tracking einsetzen,
                erstellt. Adwords-Kunden erfahren wie viele Nutzer auf ihre Anzeige geklickt haben
                und auf Seiten mit Conversion-Tracking-Tag weitergeleitet wurden. AdWords-Kunden
                erhalten jedoch keine Informationen, die eine persönliche Identifikation der Nutzer
                ermöglichen. Wenn Sie nicht am Tracking teilnehmen möchten, können Sie einer Nutzung
                widersprechen. Hier ist das Conversion-Cookie in den Nutzereinstellungen des
                Browsers zu deaktivieren. So findet auch keine Aufnahme in die Conversion-Tracking
                Statistiken statt.
              </p>
              <p>
                Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von Art. 6 Abs. 1
                lit. f DSGVO. Wir als Webseitebetreiber haben ein berechtigtes Interesse an der
                Analyse des Nutzerverhaltens, um unser Webangebot und unsere Werbung zu optimieren.
              </p>
              <p>
                Einzelheiten zu Google AdWords und Google Conversion-Tracking finden Sie in den
                Datenschutzbestimmungen von Google:{' '}
                <a className="text-lendis-main" href="https://www.google.de/policies/privacy/">
                  https://www.google.de/policies/privacy/
                </a>
                .
              </p>
              <p>
                Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen,
                einschränken oder unterbinden. Die Deaktivierung von Cookies kann eine
                eingeschränkte Funktionalität unserer Webseite zur Folge haben.
              </p>
              <h4 className="uppercase font-semibold mt-4">Social Media</h4>
              <p>Unsere Web-Site verwendet jeweils Buttons für die sozialen Netzwerke:</p>
              <p>facebook, Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA</p>
              <p>LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland</p>
              <p>Google+, Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</p>
              <p>Twitter, Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA</p>
              <p>Xing, XING AG, Gänsemarkt 43, 20354 Hamburg, Deutschland</p>
              <p>
                Die Buttons sind mit dem Logo des jeweiligen sozialen Netzwerks gekennzeichnet. Es
                handelt sich aber nicht um die üblichen Social Plugins, sondern um Buttons mit
                hinterlegten Links. Die Buttons müssen von Ihnen per Klick gesondert aktiviert
                (geklickt) werden. Erst wenn Sie die Buttons anklicken und damit Ihre Zustimmung zur
                Kommunikation mit den Servern des sozialen Netzwerks erklären, werden die Buttons
                aktiv und die Verbindung wird hergestellt.
              </p>
              <p>
                Durch die Aktivierung des Buttons erhalten die sozialen Netzwerk dann u.a. auch die
                Information, dass und wann Sie die entsprechende Seite unseres Internetauftritts
                aufgerufen haben, außerdem z.B. Ihre IP-Adresse, Angaben zum verwendeten Browser und
                zu den Spracheinstellungen.
              </p>
              <p>
                Bei Aktivierung des Buttons haben wir keinen Einfluss auf die erhobenen Daten und
                Datenverarbeitungsvorgänge und sind für diese Datenverarbeitung nicht verantwortlich
                und diesbezüglich auch nicht Verantwortlicher im Sinne der DSG-VO.
              </p>
              <p>
                Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der
                Daten durch das jeweilige soziale Netzwerk sowie Ihre diesbezüglichen Rechte und
                Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den
                Informationen:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  <a className="text-lendis-main" href="http://www.facebook.com/about/privacy">
                    facebook
                  </a>{' '}
                </li>
                <li>
                  <a
                    className="text-lendis-main"
                    href="https://www.linkedin.com/legal/privacy-policy"
                  >
                    LinkedIn
                  </a>{' '}
                </li>
                <li>
                  <a
                    className="text-lendis-main"
                    href="http://www.google.com/intl/de/policies/privacy"
                  >
                    Google
                  </a>{' '}
                </li>
                <li>
                  <a className="text-lendis-main" href="http://twitter.com/privacy">
                    Twitter
                  </a>{' '}
                </li>
                <li>
                  <a className="text-lendis-main" href="https://www.xing.com/privacy">
                    Xing
                  </a>{' '}
                </li>
              </ul>
              <h4 className="uppercase font-semibold mt-4">
                Facebook (Plug Ins, Remarketing/Custom Audiences)
              </h4>
              <p>
                Unsere Webseite benutzt Plugins des sozialen Netzwerks Facebook Inc., 1 Hacker Way,
                Menlo Park, CA 94025, USA integriert („Facebook“). Eine Übersicht über die
                Facebook-Plugins finden Sie hier:{' '}
                <a href="http://developers.facebook.com/docs/plugins/" className="text-lendis-main">
                  http://developers.facebook.com/docs/plugins/
                </a>
                .
              </p>
              <p>
                Beim Besuch unsere Internetseiten wird über das Plugin eine direkte Verbindung
                zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch
                die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie
                den Facebook “Like-Button” anklicken während Sie in Ihrem Facebook-Account
                eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil
                verlinken. Dadurch kann Facebook den Besuch unserer Seiten jeweils zuordnen. Wir
                weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
                übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Wenn Sie nicht
                wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto
                zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
              </p>
              <p>
                Nähere Informationen hierzu finden Sie unter{' '}
                <a href="https://www.facebook.com/about/privacy/" className="text-lendis-main">
                  https://www.facebook.com/about/privacy/
                </a>
              </p>
              <p>
                Auf unseren Internetseiten sind Facebook-Remarketing-Tags integriert. Wenn Sie
                unsere Seiten besuchen, wird über die Remarketing-Tags eine direkte Verbindung
                zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch
                die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Dadurch
                kann Facebook den Besuch unserer Seiten jeweils zuordnen. Die so erhaltenen
                Informationen können wir für die Anzeige von Facebook Ads nutzen. Falls Sie keine
                Datenerfassung via Custom Audience wünschen, können Sie Custom Audiences auf der
                unter Ziffer 10.1 genannten Seite mit der Facebook-Datenschutzerklärung hier
                deaktivieren.
              </p>
              <h4 className="uppercase font-semibold mt-4">Segment</h4>
              <p>
                Segment Wir verwenden auf unserem Internetauftritt "Segment", einen Dienst der
                Segment.io, Inc., 100 California Street, Suite 700, San Francisco, CA 94111, USA
                (nachfolgend bezeichnet als: "Segment"). Segment speichert und verarbeitet
                Informationen über Ihr Nutzerverhalten auf unserem Internetauftritt. Segment
                verwendet hierfür unter anderem Cookies, also kleine Textdateien, die lokal im
                Zwischenspeicher Ihres Webbrowsers auf Ihrem Endgerät gespeichert werden und die
                eine Analyse der Benutzung unseres Internetauftritts durch Sie ermöglichen.
              </p>
              <p>
                Wir verwenden Segment zu Marketing- und Optimierungszwecken, insbesondere um die
                Nutzung unseres Internetauftritts analysieren und um einzelne Funktionen und
                Angebote sowie das Nutzungserlebnis fortlaufend verbessern zu können. Durch die
                statistische Auswertung des Nutzerverhaltens können wir unser Angebot verbessern und
                für Sie als Nutzer interessanter ausgestalten. Hierin liegt auch unser berechtigtes
                Interesse an der Verarbeitung der vorstehenden Daten durch den Drittanbieter.
                Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
              </p>
              <p>
                Die Installation von Cookies können Sie verhindern, indem Sie vorhandene Cookies
                löschen und eine Speicherung von Cookies in den Einstellungen Ihres Webbrowsers
                deaktivieren. Wir weisen darauf hin, dass Sie in diesem Fall möglicherweise nicht
                alle Funktionen unseres Internetauftritts vollumfänglich nutzen können. Sie können
                der Erfassung und Weiterleitung personenbezogenen Daten widersprechen oder die
                Verarbeitung dieser Daten verhindern, indem sie die Ausführung von Java-Script in
                Ihrem Browser deaktivieren. Zudem können Sie die Ausführung von Java-Script Code
                insgesamt dadurch verhindern, indem Sie einen Java-Script-Blocker installieren (z.B.
                <a className="text-lendis-main" href="https://noscript.net/">
                  {' '}
                  https://noscript.net/
                </a>{' '}
                oder{' '}
                <a href="https://www.ghostery.com" className="text-lendis-main">
                  {' '}
                  https://www.ghostery.com
                </a>
                ). Wir weisen darauf hin, dass Sie in diesem Fall möglicherweise nicht alle
                Funktionen unseres Internetauftritts vollumfänglich nutzen können.
              </p>
              <p>
                Informationen des Drittanbieters: Segment.io, Inc., 100 California Street, Suite
                700, San Francisco, CA 94111, USA Weitere Informationen des Drittanbieters zum
                Datenschutz können Sie der nachfolgenden Webseite entnehmen:{' '}
                <a href="https://segment.com/docs/legal/privacy/" className="text-lendis-main">
                  {' '}
                  https://segment.com/docs/legal/privacy/
                </a>
              </p>
              <h4 className="uppercase font-semibold mt-4">Intercom</h4>
              <p>
                Zur Verbesserung des Nutzererlebnisses in unseren Anwendungen setzen wir für den
                Versand von Mitteilungen per E-Mail und für Live-Chats den Dienst Intercom der Firma
                der Intercom Inc. ein. Wir übermitteln als personenbezogene Daten deine
                E-Mail-Adresse und deinen Namen an Intercom.
              </p>
              <p>
                Die Intercom, Inc. hat sich dem „EU-US Privacy Shield“ Programm angeschlossen, durch
                das ein höheres Datenschutzniveau für nicht in der EU ansässige Unternehmen erreicht
                werden soll. Weitere Informationen zum Datenschutz bei Intercom findest du unter{' '}
                <a href="http://docs.intercom.io/privacy" className="text-lendis-main">
                  {' '}
                  http://docs.intercom.io/privacy
                </a>
                .
              </p>
              <h4 className="uppercase font-semibold mt-4">Mailchimp</h4>
              <p>
                Wir verwenden zum Versand unseres Newsletters das E-Mail Marketing Tool MailChimp.
                MailChimp ist ein Produkt der The Rocket Science Group, LLC, 512 Means Street, Ste
                404 Atlanta, GA 30318 und hat eine EU/US Privacy Shield Zertifizierung erworben.
              </p>
              <p>
                MailChimp bietet umfangreiche Analysemöglichkeiten darüber, wie die Mailings
                geöffnet und benutzt werden. Weitere Informationen zu MailChimp und dem Datenschutz
                bei MailChimp finden Sie hier:
                <a href="https://mailchimp.com/legal/privacy/" className="text-lendis-main">
                  {' '}
                  https://mailchimp.com/legal/privacy/
                </a>
              </p>
              <h4 className="uppercase font-semibold mt-4">Hotjar</h4>
              <p>
                Diese Website benutzt Hotjar, eine Analysesoftware der Hotjar Ltd. („Hotjar“) (
                <a href="http://www.hotjar.com" className="text-lendis-main">
                  {' '}
                  http://www.hotjar.com
                </a>
                , 3 Lyons Range, 20 Bisazza Street, Sliema SLM 1640, Malta, Europe). Mit Hotjar ist
                es möglich das Nutzungsverhalten (Klicks, Mausbewegungen, Scrollhöhen, etc.) auf
                unserer Website zu messen und auszuwerten. Die Informationen die dabei durch den
                „Tracking Code“ und Cookies über Ihren Besuch auf unserer Website generiert werden,
                werden an die Hotjar Server in Irland übermittelt und dort gespeichert.
              </p>
              <p>
                Die folgenden Informationen können durch Ihr Gerät und Ihren Browser aufgezeichnet
                werden:
              </p>
              <p className="pl-20">
                Die IP-Adresse Ihres Gerätes (gesammelt und abgespeichert in einem anonymisierten
                Format)
                <br />
                Ihre E-Mail-Adresse inklusive Ihres Vor- und Nachnamens, soweit Sie diese über
                unsere Website uns zur Verfügung gestellt haben
                <br />
                Bildschirmgröße Ihres Gerätes
                <br />
                Gerätetyp und Browserinformation
                <br />
                Geographischer Standpunkt (nur das Land)
                <br />
                Die bevorzugte Sprache, um unsere Website darzustellen
                <br />
                Log Daten
              </p>
              <p>
                Die folgenden Daten werden automatisch durch unsere Server erstellt, wenn Hotjar
                benutzt wird
              </p>
              <p className="pl-20">
                Bezug nehmende Domain
                <br />
                Besuchte Seiten
                <br />
                Geographischer Standpunkt (nur das Land)
                <br />
                Die bevorzugte Sprache, um unsere Website darzustellen
                <br />
                Datum und Zeit, zu der auf die Website zugegriffen wurde
              </p>
              <p>
                Hotjar wird diese Informationen benutzen, um Ihren Besuch unserer Website
                auszuwerten, Berichte der Nutzung zu erstellen, sowie sonstige Dienstleistungen
                auszuwerten, die die Websitebenutzung und Internetauswertung der Website betreffen.
              </p>
              <p>
                Hotjar benutzt zur Leistungserbringung auch Dienstleistungen von Drittunternehmen,
                wie beispielsweise Google Analytics und Optimizely. Diese Drittunternehmer können
                Informationen, die Ihr Browser im Rahmen des Website-Besuches sendet, wie
                beispielsweise Cookies oder IP-Anfragen, speichern. Für nähere Informationen, wie
                Google Analytics und Optimizely Daten speichern und verwenden, beachten Sie bitte
                deren entsprechende Datenschutzerklärungen.
              </p>
              <p>
                Die Cookies, die Hotjar nutzt, haben eine unterschiedliche „Lebensdauer“. Manche
                bleiben bis zu 365 Tagen bestehen, manche bleiben nur während des aktuellen Besuchs
                gültig.
              </p>
              <p>
                Sie können die Erfassung der Daten durch Hotjar verhindern, indem Sie auf folgenden
                Link klicken und die dortigen Instruktionen befolgen:{' '}
                <a href="https://www.hotjar.com/opt-out" className="text-lendis-main">
                  {' '}
                  https://www.hotjar.com/opt-out
                </a>
                .
              </p>
              <h4 className="uppercase font-semibold mt-4">Tracking durch ELDAR</h4>
              <p>
                Wenn Sie unsere Internetseiten aufrufen, speichert der ELDAR-Server folgende Daten:
              </p>
              <p className="pl-20">
                den verwendeten Browsertyp und -version,
                <br />
                das verwendete Betriebssystem,
                <br />
                die angesteuerten Seiten unserer Website,
                <br />
                das Datum und die Uhrzeit Ihres Zugriffs,
                <br />
                Ihre Internet-Protokoll-Adresse (IP-Adresse) und
                <br />
                Ihren Internet-Service-Provider.
              </p>
              <p>
                Wir verwenden diese Daten in anonymisierter Form, d.h. ohne die Möglichkeit, Sie zu
                identifizieren,
              </p>
              <p className="pl-20">
                für statistische Zwecke,
                <br />
                um technische Probleme zu erkennen und zu beheben,
                <br />
                um die Website zu verbessern und
                <br />
                für die Einschätzung, ob Ihr Unternehmen (nicht Sie persönlich) an unserem
                Dienstleistungsangebot interessiert sein könnte. Hierzu setzen wir die Software
                ELDAR der OPTANIUM GmbH ein.
              </p>
              <p>
                ELDAR recherchiert automatisiert, welche juristische Person (Unternehmen) Ihre
                IP-Adresse registriert hat, die ersichtlich keine private oder mobile Nutzung
                unterstützt. Nur soweit das der Fall ist, speichert ELDAR den Unternehmensnamen und
                recherchiert die zu diesem Unternehmensnamen im Internet oder bei Auskunfteien
                öffentlich verfügbaren Daten. Die IP-Adresse wird nach der Feststellung der
                Unternehmensbezogenheit gelöscht. Die öffentlich verfügbaren Daten verbindet ELDAR
                mit einer Zusammenfassung, wann welche Seiten unserer Website besucht wurden und
                übergibt diesen Datensatz unserem Verkaufspersonal zur Aufnahme von
                Marketingkontakten mit ihrem Unternehmen. Der Bericht enthält keinerlei auf Sie
                persönlich hindeutende bzw. identifizierbare Daten.
                <br />
                ELDAR wird ausschließlich auf Servern betrieben, welche sich in Deutschland
                befinden.
                <br />
                Die Rechtsgrundlage für die Verarbeitung personenbezogener Nutzungsdaten ist Artikel
                6 Absatz 1 Unterabsatz 1 Buchstabe f DSGVO.
                <br />
                Die OPTANIUM GmbH erhebt und verarbeitet diese Daten im Auftrag der Lendis GmbH
                gemäß des geschlossenen Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1
                DSGVO.
                <br />
                Die Verarbeitung der genannten Daten durch ELDAR können Sie{' '}
                <a href="mailto:optout@optanium.com" className="text-lendis-main">
                  {' '}
                  hier
                </a>{' '}
                unterbinden.
              </p>
              <h4 className="uppercase font-semibold mt-4">Profiling</h4>
              <p>
                Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten
                Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu
                werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise
                erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
              </p>
              <ul className="list-disc list-inside">
                <li>
                  für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns
                  erforderlich ist,
                </li>
                <li>
                  aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen wir
                  unterliegen, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur
                  Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten
                  oder
                </li>
                <li> mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
              </ul>
              <h4 className="uppercase font-semibold mt-4">
                Änderungen dieser Datenschutzerklärung
              </h4>
              <p>
                Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit mit Wirkung für
                die Zukunft zu ändern. Eine jeweils aktuelle Version ist auf unserer Webseite
                verfügbar. Bitte suchen Sie unseren Internet-Auftritt regelmäßig auf und informieren
                Sie sich über die geltenden Datenschutzbestimmungen.
              </p>
              <p>
                Beachten sie auch, dass sich Datenschutzbestimmungen und Handhabungen zum
                Datenschutz auch bei Dritten, z. B. Google oder Facebook, laufend ändern können. Es
                ist daher ratsam und erforderlich, sich über Veränderungen der gesetzlichen
                Bestimmungen und der Praxis von Unternehmen laufend zu informieren.
              </p>
              <h4 className="uppercase font-semibold mt-4">Kontakt</h4>
              <p>
                Falls Sie Fragen, Kommentare und Anfragen bezüglich dieser Datenschutzerklärung
                haben, wenden Sie sich bitte an: info@lendis.io
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default DataPrivacy
